import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { MediaData } from '../models/eventDetails';
import { ApiConstants } from '../constants/api-constant';
import { RequestId, ZoneConfigurationDto, ZoneConfigurationResponse } from '../models/zone-configuration';
import { ZoneSetting } from '../models/zoneSetting';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  public requetImageDetails$: Observable<RequestId>;
  public showRequestImageLoader = signal<boolean>(false);
  public disableRequestImageButton = signal<boolean>(false);
  public isValueChange: boolean;
  private baseUrl: string;
  private selectedRequestImage = new BehaviorSubject<RequestId>({} as RequestId);
  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
    this.requetImageDetails$ = this.selectedRequestImage.asObservable();
  }

  public getRequestImage(gatewayId: string, controllerId: string): Observable<RequestId> {
    return this.http.get<RequestId>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}request-current-image/${gatewayId}/${controllerId}`
    );
  }

  public getRequestVideo(gatewayId: string, controllerId: string): Observable<RequestId> {
    return this.http.get<RequestId>(`${this.baseUrl}${ApiConstants.BASEURL}request-current-video/${gatewayId}/${controllerId}`);
  }

  public getZoneConfig(controllerId: string, workflow: string): Observable<ZoneConfigurationDto> {
    return this.http.get<ZoneConfigurationDto>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONECONFIGURATION}get-zone-config/${controllerId}/${workflow}`
    );
  }

  public getZoneImage(mediaRequestId: string): Observable<MediaData> {
    return this.http.get<MediaData>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONECONFIGHISHTORY}get-zone-config-media/${mediaRequestId}`
    );
  }

  public saveZoneConfig(zoneDetails: ZoneConfigurationDto): Observable<ZoneConfigurationResponse> {
    return this.http.post<ZoneConfigurationResponse>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONECONFIGURATION}save-zone-config`,
      zoneDetails
    );
  }

  public getZoneSetting(zoneId: string): Observable<ZoneSetting> {
    return this.http.get<ZoneSetting>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONESETTING}/${zoneId}`);
  }

  public saveZoneSetting(zoneConfig: ZoneSetting, zoneId: string): Observable<any> {
    if (zoneId) {
      return this.http.patch<any>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONESETTING}/${zoneId}`, zoneConfig);
    } else {
      return this.http.post<any>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONESETTING}`, zoneConfig);
    }
  }

  public getCommandStatus(controllerId: string, isLast: boolean): Observable<string> {
    return this.http.get<string>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONESETTING}?equipmentId=${controllerId}&isLast=${isLast}`
    );
  }

  public zoneConfigHistory(controllerId: string): Observable<MediaData> {
    return this.http.get<MediaData>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.ZONECONFIGHISHTORY}${ApiConstants.GETEQUIPMENTMEDIA}${controllerId}`
    );
  }

  public setRequestImageData(requestData: RequestId): void {
    this.selectedRequestImage.next(requestData);
  }
}
