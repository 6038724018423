import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, signal, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { tap, takeUntil, finalize, retry, expand, catchError, delay, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, Subject, Subscription, fromEvent, of } from 'rxjs';
import { MessageService, SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { ZoneService } from '../../shared/services/zone.service';
import {
  LineCoordinates,
  RequestId,
  ZoneConfiguration,
  ZoneConfigurationDto,
  ZoneConfigurationResponse,
} from '../../shared/models/zone-configuration';
import { MediaData } from '../../shared/models/eventDetails';
import { ZoneConfigurationConstants } from '../../shared/constants/zone-config.constant';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '@agora/agora-ui-library';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { Location } from '@angular/common';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ZoneSettingsConstants } from '../../shared/constants/zone-setting-constants';
import { ZoneConfig, ZoneSetting } from '../../shared/models/zoneSetting';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { ZoneSettingComponent } from '../zone-setting/zone-setting.component';
import { GlobalViewService } from '../../shared/services/global-view.service';
import { WorkFlow } from '../../shared/models/global-view';
import { REDZONE } from '../../shared/constants/camera-profile-constant';

@Component({
  selector: 'app-zone-definition',
  templateUrl: './zone-definition.component.html',
  styleUrls: ['./zone-definition.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZoneDefinitionComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('zoneNameField') zoneNameField: NgModel;
  @ViewChild(ZoneSettingComponent) zoneSetting: ZoneSettingComponent;

  public mediaUrl: SafeUrl;
  public selectedMediaFormat: string;
  public zoneName: string;
  public zoneConfiguration!: ZoneConfiguration;
  public hasZone = false;
  public deviceWidth = 800;
  public deviceHeight = 450;
  public controllerId: string;
  public gatewayId: string;
  public image: CanvasImageSource;
  public sessionToken: string;
  public isZoneChange: boolean;
  public inactiveGateway: boolean;
  public cameraName: string;
  public rigName: string;
  public workflow = '';
  public previewError = '';
  public imageLoader = signal(false);
  public currentZone = '';
  public gatewayStatus: string;
  public cameraStatus: string;
  public isZoneNameValid = true;
  public isIntersecting = false;
  public zoneConfigLoader = false;
  public noZoneConfiguration = false;
  public deviceOffline = false;
  public newZoneChange = false;
  public showConfirmation = false;
  public ppeEventsOptions = ZoneConfigurationConstants.PPEEVENTS;
  public redZoneTypes: RadioButtonValue[] = ZoneSettingsConstants.REDZONETYPE_RADIO_OPTIONS;
  public inactiveCamera: boolean;
  public currentRedZone: number;
  public buLogic: number;
  public zoneId: number;
  public existingZoneSetting: ZoneSetting;
  public status: string;
  public updatedBy?: string;
  public updatedOn: string | null;
  public workflowData: WorkFlow[];
  public workflowName: string;
  private requestedImage: string;
  private isFileSelected = false;
  private ctx!: CanvasRenderingContext2D;
  private destroyed = new Subject();
  private destroyApi = new Subject();
  private pointFlowSubscription!: Subscription;
  private readonly noOfPoints = 20;
  private delay = 10000;
  private init = 0;

  constructor(
    private _DomSanitizationService: DomSanitizer,
    private messageService: MessageService,
    public zoneService: ZoneService,
    private loginService: LoginService,
    private timeZoneService: TimeZoneService,
    private route: ActivatedRoute,
    private location: Location,
    private cameraService: CameraProfileService,
    private globalService: GlobalViewService,
    private cameraProfileService: CameraProfileService
  ) {
    this.currentZone = this.timeZoneService.getTimeZone();
    this.zoneService.disableRequestImageButton.set(true);
    this.cameraService.selectedZoneId$
      .pipe(
        tap((params: ZoneConfig) => {
          if (params) {
            this.buLogic = params.buLogic === null ? 2 : Number(params.buLogic);
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
    this.route.params
      .pipe(
        tap(params => {
          if (params) {
            this.previewError = '';
            this.gatewayId = params.gateway;
            this.controllerId = params.controllerId;
            this.cameraName = params.cameraName;
            this.cameraStatus = params.cameraStatus;
            this.rigName = params.rigName;
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.gatewayStatus = sessionStorage.getItem('gatewayStatus') ?? '';
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    this.zoneConfigLoader = false;
    this.isZoneChange = false;
    this.previewError = '';
    this.updatedOn = '';
    this.ppeEventsOptions.some(e => (e.isChecked = false));
    this.imageLoader = this.zoneService.showRequestImageLoader;
    this.inactiveCamera = false;
    if (this.gatewayStatus) {
      this.inactiveGateway = this.gatewayStatus !== '0' ? true : false;
      if (!this.inactiveGateway) {
        this.inactiveCamera = this.cameraStatus !== '0' ? true : false;
      }
    }
    this.cameraProfileService
      .getWorkFlowByControllerId(this.controllerId)
      .pipe(
        tap((workflowData: WorkFlow[]) => {
          // this.workflowData = workflowData.filter(data => data?.name?.toLowerCase() === REDZONE?.toLowerCase());
          if (workflowData?.length > 0) {
            this.workflowData = workflowData.filter(data => data?.name?.toLowerCase() === REDZONE?.toLowerCase());

            if (this.workflowData.length > 0) {
              this.workflow = this.workflowData?.[0]?.id;
              this.workflowName = this.workflowData?.[0]?.name;
              this.getZoneConfig(true);
            } else {
              this.getZoneConfigHistory();
            }
          } else {
            this.workflowData = [];
            this.zoneService.disableRequestImageButton.set(true);
            this.zoneService.showRequestImageLoader.set(false);
            this.zoneConfigLoader = false;
          }
        }),
        switchMap(() =>
          this.zoneService.requetImageDetails$.pipe(
            tap((params: RequestId) => {
              if (params && params?.mediaRequestId) {
                this.previewError = '';
                this.processMediaData(params.mediaRequestId);
              }
            }),
            takeUntil(this.destroyApi)
          )
        )
      )
      .subscribe();
    this.disableSave();
  }

  public ngAfterViewInit(): void {
    this.ctx = (this.canvas?.nativeElement as HTMLCanvasElement).getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    this.zoneConfigLoader = true;
    this.zoneService.showRequestImageLoader.set(true);
  }

  public setPpeOptions(): void {
    this.ctx.strokeStyle = this.isRedZone(this.zoneConfiguration.workflow) ? '#FF0000' : '#EA910D';
    this.ctx.fillStyle = this.isRedZone(this.zoneConfiguration.workflow) ? '#FF0000' : '#EA910D';
    const subTypeList = this.zoneConfiguration.subType ? this.zoneConfiguration.subType.split(',') : [];
    if (subTypeList.length > 0) {
      subTypeList.map((_subType: string) => {
        const index = this.ppeEventsOptions.findIndex(event => event.label === _subType);
        this.ppeEventsOptions[index].isChecked = true;
      });
    } else {
      this.ppeEventsOptions = ZoneConfigurationConstants.PPEEVENTS;
    }
  }

  public saveZoneConfiguration(): void {
    this.zoneConfigLoader = true;
    if (this.zoneName?.length && this.zoneConfiguration?.xyCoordinates?.length > 0) {
      this.zoneConfiguration.workflow = this.workflowData.filter(data => data?.id === this.workflow)?.[0]?.name;
      this.zoneConfiguration.zoneName = this.zoneName.trim();
      this.zoneConfiguration.controllerId = this.controllerId;
      this.zoneConfiguration.updatedBy = this.loginService.getUserInfo()?.email ?? '';
      this.zoneConfiguration.timestamp = new Date().toISOString();
      this.updatedBy = this.zoneConfiguration.updatedBy;
      this.updatedOn = this.zoneConfiguration.timestamp;
      if (this.workflow === 'PPE') {
        this.zoneSetting.ppeLoader(true);
        this.zoneConfiguration.subType =
          this.zoneConfiguration.workflow === 'PPE'
            ? this.ppeEventsOptions
                .filter(e => e.isChecked)
                .map(ev => ev.label)
                .toString()
            : '';
        this.noZoneConfiguration = false;
      } else {
        if (this.isRedZone(this.workflow) && this.zoneService.isValueChange && this.allValid(this.zoneSetting.isValid)) {
          this.zoneSetting.ppeLoader(true);
          this.zoneSetting.saveZoneSettings();
        }
      }
      if (this.isZoneChange && this.isZoneNameValid) {
        const zoneConfiguration: ZoneConfigurationDto = {
          zoneConfiguration: this.zoneConfiguration,
        };
        this.zoneService
          .saveZoneConfig(zoneConfiguration)
          .pipe(
            tap((_zoneData: ZoneConfigurationResponse) => {
              this.messageService.add({
                severity: SlbSeverity.Success,
                summary: 'Success',
                detail: ZoneConfigurationConstants.ZONEREQUESTSUCCESS,
              });
              this.zoneName = this.zoneName.trim();
              this.setPpeOptions();
              this.zoneConfigLoader = false;
              this.isZoneChange = false;
              this.newZoneChange = false;
              this.zoneSetting.ppeLoader(false);
              this.zoneService.isValueChange = false;
            }),
            catchError(() => {
              this.messageService.add({
                severity: SlbSeverity.Info,
                summary: 'Information',
                detail: ZoneConfigurationConstants.ZONESAVEERROR,
              });
              this.zoneConfigLoader = false;
              this.zoneSetting.ppeLoader(false);

              return of<ZoneConfiguration>({} as ZoneConfiguration);
            }),
            takeUntil(this.destroyApi)
          )
          .subscribe();
      } else {
        this.zoneConfigLoader = false;
        this.isZoneChange = false;
        this.zoneService.isValueChange = false;
      }
    }
  }

  public resetZone(): void {
    this.hasZone = false;
    this.isZoneNameValid = true;
    this.isIntersecting = false;
    if (this.canvas && (!this.pointFlowSubscription || this.pointFlowSubscription?.closed) && this.workflowName === REDZONE.toLowerCase()) {
      this.drawOnCanvas();
    }
  }

  public removeRedzone(): void {
    this.zoneConfiguration.xyCoordinates = [];
    this.zoneNameField.reset();
    this.zoneName = '';
    this.ppeEventsOptions.some(e => (e.isChecked = false));
    this.resetZone();
    this.setFile(this.zoneConfiguration.zoneConfigFileUrl + '/?token=' + this.sessionToken);
  }

  public navigateToCameraScreen(): void {
    this.location.back();
  }

  public isRedZone(workflow: string): boolean {
    let isRedzone = false;
    if (this.workflowData?.length && workflow) {
      isRedzone = this.workflowData.filter(data => data?.name?.toLowerCase() === REDZONE?.toLowerCase())?.[0]?.id === workflow;
    }

    return isRedzone;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
    this.destroyApi.next(true);
    this.destroyApi.complete();
    this.cameraProfileService.cameraWorkflow.set([]);
    this.pointFlowSubscription?.unsubscribe();
    this.zoneService.setRequestImageData({} as RequestId);
  }

  public checkZoneNameValid(): void {
    if (this.zoneName) {
      this.newZoneChange = true;
      const exactMatch = new RegExp('^[a-zA-Z0-9- ]*$');
      const matchResult = this.zoneName.match(exactMatch);
      this.isZoneChange = true;
      this.isZoneNameValid = matchResult ? true : false;
    }
  }

  public changeWorkflow(): void {
    if (this.newZoneChange) {
      this.showConfirmation = true;
      const confirmation: SlbMessage = {
        target: 'modal',
        severity: SlbSeverity.Info,
        summary: 'Information',
        detail: 'The configuration is not saved, please save the configuration',
        config: {
          primaryAction: 'Ok',
          secondaryAction: 'Cancel',
          secondaryActionCallback: () => {
            this.isZoneChange = false;
            this.getZoneConfig(false);
            this.workflowName = this.getWorkflowName(this.workflow);
            this.showConfirmation = false;
          },
          primaryActionCallback: () => {
            this.workflow = this.getWorkflowId();
            this.showConfirmation = false;
          },
        },
      };
      this.messageService.add(confirmation);
    } else {
      this.changeWorkFlowPopup();
    }
  }

  public changeWorkFlowPopup(): void {
    if (this.zoneService.isValueChange) {
      this.showConfirmation = true;
      this.zoneSetting.showLoader = true;
      const confirmation: SlbMessage = {
        target: 'modal',
        severity: SlbSeverity.Info,
        summary: 'Information',
        detail: 'The configuration settings are not saved, please save the configuration',
        closable: false,
        config: {
          primaryAction: 'Ok',
          primaryActionCallback: () => {
            this.workflow = this.getWorkflowId();
            this.zoneSetting.showLoader = false;
          },
        },
      };
      this.messageService.add(confirmation);
    } else {
      this.workflowName = this.getWorkflowName(this.workflow);
      this.zoneConfiguration.xyCoordinates = [];
      this.getZoneConfig(false);
    }
  }

  public disableSave(): boolean {
    return (
      this.imageLoader() ||
      this.deviceOffline ||
      this.inactiveGateway ||
      this.inactiveCamera ||
      !(
        (this.zoneService.isValueChange || this.isZoneChange) &&
        this.allValid(this.zoneSetting.isValid) &&
        this.isZoneNameValid &&
        this.zoneName.trim()?.length > 0
      )
    );
  }

  public allValid = (arr: boolean[]): boolean => arr.every(val => val === true);

  private setFile(file: string): void {
    if (file) {
      this.isFileSelected = true;
      if (file.indexOf('jpg') > -1 || file.indexOf('jpeg') > -1 || file.indexOf('png') > -1) {
        this.selectedMediaFormat = 'image';
        this.mediaUrl = this._DomSanitizationService.bypassSecurityTrustUrl(file);
        const img = new Image();
        img.src = file;
        img.onload = (): void => {
          this.image = img;
          this.setCoordinates(img);
        };
      }
      if (this.workflowName) {
        this.setPpeOptions();
      } else {
        this.noZoneConfiguration = true;
      }
    } else {
      this.previewError = ZoneConfigurationConstants.NOZONEDEFINATION;
    }
  }

  private showOfflineToaster(): void {
    if (this.gatewayStatus !== '0' || this.cameraStatus !== '0') {
      this.deviceOffline = true;
      this.zoneService.disableRequestImageButton.set(true);
      this.messageService.add({
        severity: SlbSeverity.Info,
        summary: 'Information',
        detail: ZoneConfigurationConstants.DEVICEOFFLINEWITHZONECONFIG,
      });
    }
  }

  private getZoneConfig(intial: boolean): void {
    this.zoneService
      .getZoneConfig(this.controllerId, this.workflow)
      .pipe(
        tap((zoneData: ZoneConfigurationDto) => {
          if (zoneData && zoneData.detail !== ZoneConfigurationConstants.DETAILERROR) {
            const zoneDetails: ZoneConfiguration = zoneData.zoneConfiguration;
            this.zoneConfiguration = zoneDetails;
            this.zoneConfiguration.zoneConfigFileUrl = this.requestedImage ? this.requestedImage : this.zoneConfiguration.zoneConfigFileUrl;
            this.zoneName = zoneDetails.zoneName;
            this.updatedOn = this.zoneConfiguration.timestamp;
            this.updatedBy = this.zoneConfiguration.updatedBy;
            if (
              this.zoneSetting?.updatedOn &&
              this.updatedOn &&
              new Date(this.zoneSetting.updatedOn).getTime() > new Date(this.updatedOn).getTime()
            ) {
              this.updatedOn = this.zoneSetting.updatedOn;
              this.updatedBy = this.zoneSetting.updatedBy;
            }
            this.setFile(zoneDetails.zoneConfigFileUrl + '/?token=' + this.sessionToken);
          } else {
            this.initZoneData();
          }
          this.showOfflineToaster();
          this.zoneConfigLoader = false;
          this.zoneService.showRequestImageLoader.set(false);
          this.zoneService.disableRequestImageButton.set(false);
        }),
        catchError(() => {
          this.zoneService.disableRequestImageButton.set(false);
          if (intial && this.workflowData.length > 1) {
            this.workflow = this.workflowData?.[1]?.id;
            this.workflowName = this.workflowData?.[1]?.name;
            this.getZoneConfig(false);
          } else {
            this.showOfflineToaster();
            this.zoneConfigLoader = false;
            this.zoneService.showRequestImageLoader.set(false);
            this.mediaUrl = '';
            this.previewError = ZoneConfigurationConstants.NOZONEDEFINATION;
            this.noZoneConfiguration = true;
          }

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  private initZoneData(): void {
    this.initiateZoneConfig();
    this.zoneConfiguration.zoneConfigFileUrl = this.requestedImage ? this.requestedImage : this.zoneConfiguration.zoneConfigFileUrl;
    this.noZoneConfiguration = true;
    this.removeRedzone();
    if (this.gatewayStatus !== '0' || this.cameraStatus !== '0') {
      this.deviceOffline = true;
      this.previewError = ZoneConfigurationConstants.GATEWAYCAMERADISABLEERROR;
    }
    if (this.noZoneConfiguration && !this.requestedImage) {
      this.previewError = ZoneConfigurationConstants.NOZONEDEFINATION;
    }
  }

  private processMediaData(requestid: string): void {
    this.init = 0;
    const getMedia = this.getZoneImageApi(requestid);
    getMedia
      .pipe(
        expand((result: MediaData) => (!result?.media?.fileUrl ? this.getZoneImageApi(requestid) : EMPTY).pipe(delay(this.delay))),
        tap((mediaData: MediaData) => {
          if (mediaData && mediaData?.media?.fileUrl) {
            this.previewError = '';
            if (!this.zoneConfiguration) {
              this.initiateZoneConfig();
            }
            this.zoneService.disableRequestImageButton.set(false);
            this.resetZone();
            this.pointFlowSubscription?.unsubscribe();
            this.mediaUrl = '';
            this.zoneService.showRequestImageLoader.set(false);
            if (this.canvas) {
              this.ctx = (this.canvas?.nativeElement as HTMLCanvasElement).getContext('2d', {
                willReadFrequently: true,
              }) as CanvasRenderingContext2D;
              if (this.pointFlowSubscription?.closed && this.workflowName === REDZONE.toLowerCase()) {
                this.drawOnCanvas();
              }
            }
            this.setFile(mediaData?.media?.fileUrl + '/?token=' + this.sessionToken);
            this.zoneConfiguration.zoneConfigFileUrl = mediaData.media.fileUrl;
            this.requestedImage = this.zoneConfiguration.zoneConfigFileUrl;
            if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
              this.hasZone = true;
              this.isIntersecting = true;
              this.destroyed.next(true);
            }
            this.checkZoneNameValid();
          }
        }),
        retry({ count: 14, delay: this.delay }),
        catchError((err: any) => {
          if (err !== '') {
            this.hasZone = false;
            if (this.zoneConfiguration?.zoneConfigFileUrl === '') {
              this.previewError = ZoneConfigurationConstants.NOZONEDEFINATION;
            }
            if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
              this.hasZone = true;
            }
            this.zoneService.disableRequestImageButton.set(false);
            this.zoneService.showRequestImageLoader.set(false);
            this.messageService.add({
              severity: SlbSeverity.Error,
              summary: 'Error',
              detail: ZoneConfigurationConstants.ZONEGETMEDIAERROR,
            });
          }

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  private getZoneImageApi(requestid: string): Observable<MediaData> {
    this.init += 1;
    if (this.init === 1) {
      setTimeout(() => {
        return this.zoneService.getZoneImage(requestid);
      }, this.delay);
    } else {
      return this.zoneService.getZoneImage(requestid);
    }

    return of({} as MediaData);
  }

  private setCoordinates(img: CanvasImageSource): void {
    this.ctx.strokeStyle = this.isRedZone(this.workflow) ? '#FF0000' : '#EA910D';
    this.ctx.font = '20px Arial';
    this.ctx.lineWidth = 2;
    this.ctx.drawImage(img, 0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
      this.hasZone = true;
      this.ctx.beginPath();
      this.ctx.moveTo(this.zoneConfiguration.xyCoordinates?.[0].x, this.zoneConfiguration.xyCoordinates?.[0].y);
      for (let i = 0; i < this.zoneConfiguration.xyCoordinates.length - 1; i++) {
        this.ctx.lineTo(this.zoneConfiguration.xyCoordinates?.[i + 1]?.x, this.zoneConfiguration.xyCoordinates?.[i + 1]?.y);
        this.ctx.stroke();
        this.ctx.globalAlpha = 1;
      }
      this.highlightZone();
      this.ctx.beginPath();
      this.ctx.moveTo(this.zoneConfiguration.xyCoordinates?.[0]?.x, this.zoneConfiguration.xyCoordinates?.[0]?.y);
      this.ctx.lineTo(
        this.zoneConfiguration.xyCoordinates[this.zoneConfiguration.xyCoordinates?.length - 1]?.x,
        this.zoneConfiguration.xyCoordinates[this.zoneConfiguration.xyCoordinates?.length - 1]?.y
      );

      this.ctx.stroke();
      this.ctx.closePath();
      this.isFileSelected = false;
    }
  }

  /* eslint-disable max-lines-per-function */
  private drawOnCanvas(): void {
    this.newZoneChange = false;
    const clickStream = fromEvent(this.canvas?.nativeElement, 'click');
    const mouseMoveStream = fromEvent(this.canvas?.nativeElement, 'mousemove');
    let mousex = 0;
    let mousey = 0;
    const storedLines: LineCoordinates[] = [];
    let isDown: boolean;

    this.pointFlowSubscription = clickStream
      .pipe(
        tap((clickStreamEvent: Event) => {
          if (!Number(this.noOfPoints) || this.isIntersecting || !this.isFileSelected) {
            return;
          }
          const mouseClick = clickStreamEvent as MouseEvent;
          isDown = true;
          mousex = mouseClick.offsetX;
          mousey = mouseClick.offsetY;
          if (this.zoneConfiguration?.xyCoordinates?.length) {
            const previousValue = this.zoneConfiguration?.xyCoordinates[this.zoneConfiguration?.xyCoordinates.length - 1];
            if (mousex === previousValue.x && mousey === previousValue.y) {
              return;
            }
          }
          if (!this.zoneConfiguration?.xyCoordinates.length && this.zoneConfiguration?.zoneConfigFileUrl?.length) {
            this.ctx.lineJoin = 'round';
            this.ctx.lineWidth = 2;
            this.zoneConfiguration.xyCoordinates.push({
              x: mouseClick.offsetX,
              y: mouseClick.offsetY,
            });
          } else if (this.zoneConfiguration?.xyCoordinates.length < this.noOfPoints) {
            this.newZoneChange = true;
            const i = this.zoneConfiguration.xyCoordinates.length - 1;
            storedLines.push({
              x1: this.zoneConfiguration.xyCoordinates[i].x,
              y1: this.zoneConfiguration.xyCoordinates[i].y,
              x2: mouseClick.offsetX,
              y2: mouseClick.offsetY,
            });
            this.mouseStream(mouseClick.offsetX, mouseClick.offsetY);
          }
        }),
        switchMap(() =>
          mouseMoveStream.pipe(
            tap((mouseMoveEvent: Event) => {
              const mouseMove = mouseMoveEvent as MouseEvent;
              mouseMove.preventDefault();
              mouseMove.stopPropagation();
              this.redrawStoredLines(storedLines);
              if (!isDown) {
                return;
              }

              const mouseX = mouseMove.offsetX;
              const mouseY = mouseMove.offsetY;

              // draw the current line
              if (!storedLines.length) {
                this.ctx.beginPath();
                this.ctx.moveTo(mousex, mousey);
              }

              this.ctx.lineTo(mouseX, mouseY);

              this.ctx.stroke();
              // check for intersections
              if (this.zoneConfiguration?.xyCoordinates?.length >= 4) {
                this.checkForIntersectionRunTime(storedLines, mouseX, mouseY);
              }
            }),
            takeUntil(this.destroyed)
          )
        ),
        takeUntil(this.destroyed),
        finalize(() => {
          this.ctx.closePath();
        })
      )
      .subscribe();
  }

  private mouseStream(xCoord: number, yCoord: number): void {
    this.zoneConfiguration.xyCoordinates.push({
      x: xCoord,
      y: yCoord,
    });
    // check for intersections
    if (this.zoneConfiguration?.xyCoordinates.length >= 4) {
      const max = this.zoneConfiguration?.xyCoordinates.length - 1;
      this.checkForIntersection(max);
    }
    // connect the last points with the first point if user have reached limit of 20 points and still not intersceting.
    if (this.zoneConfiguration?.xyCoordinates.length === this.noOfPoints && !this.isIntersecting) {
      this.ctx.lineTo(this.zoneConfiguration?.xyCoordinates[0].x, this.zoneConfiguration?.xyCoordinates[0].y);
      this.ctx.stroke();
      this.highlightZone();
      this.destroyed.next(true);
      this.hasZone = true;
    }
  }

  private checkForIntersection(max: number): void {
    // length = 4 - 0,1,2,3
    // length = 5 - 0,1,3,4 | 1,2,3,4 |
    // length = 6 - 0,1,4,5 | 1,2,4,5 | 2,3,4,5
    for (let i = 0; i < max && i + 1 !== max - 1; i++) {
      const p0 = this.zoneConfiguration?.xyCoordinates[i];
      const p1 = this.zoneConfiguration?.xyCoordinates[i + 1];
      const p2 = this.zoneConfiguration?.xyCoordinates[max - 1];
      const p3 = this.zoneConfiguration?.xyCoordinates[max];
      const result = this.checkIfLineIntersecting(p0.x, p0.y, p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
      if (result) {
        this.isIntersecting = true;
        this.ctx.lineTo(this.zoneConfiguration.xyCoordinates[max].x, this.zoneConfiguration.xyCoordinates[max].y);
        this.highlightZone();
        this.hasZone = true;
        this.destroyed.next(true);
        break;
      }
    }
  }

  private checkForIntersectionRunTime(_storedLines: LineCoordinates[], x: number, y: number): void {
    // length = 4 - 0,1,2,3
    // length = 5 - 0,1,3,4 | 1,2,3,4 |
    // length = 6 - 0,1,4,5 | 1,2,4,5 | 2,3,4,5
    const max = this.zoneConfiguration?.xyCoordinates.length - 1;
    for (let i = 0; i < max && i + 1 !== max - 1; i++) {
      const p0 = this.zoneConfiguration?.xyCoordinates[i];
      const p1 = this.zoneConfiguration?.xyCoordinates[i + 1];
      const p2 = this.zoneConfiguration?.xyCoordinates[max - 1];
      const p3 = { x, y };
      const result = this.checkIfLineIntersecting(p0.x, p0.y, p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
      if (result) {
        this.highlightZone();
        break;
      }
    }
  }

  private checkIfLineIntersecting(
    p0_x: number,
    p0_y: number,
    p1_x: number,
    p1_y: number,
    p2_x: number,
    p2_y: number,
    p3_x: number,
    p3_y: number
  ): number {
    const s1X = p1_x - p0_x;
    const s1Y = p1_y - p0_y;
    const s2X = p3_x - p2_x;
    const s2Y = p3_y - p2_y;

    const s = (-s1Y * (p0_x - p2_x) + s1X * (p0_y - p2_y)) / (-s2X * s1Y + s1X * s2Y);
    const t = (s2X * (p0_y - p2_y) - s2Y * (p0_x - p2_x)) / (-s2X * s1Y + s1X * s2Y);

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
      return 1;
    }

    return 0; // No collision
  }

  private highlightZone(): void {
    this.ctx.strokeStyle = this.isRedZone(this.workflow) ? '#FF0000' : '#EA910D';
    this.ctx.font = '20px Arial';
    this.ctx.fillStyle = this.isRedZone(this.workflow) ? '#FF0000' : '#EA910D';
    this.ctx.globalAlpha = 0.2;
    this.ctx.fill();
    this.ctx.globalAlpha = 1;
  }

  private redrawStoredLines(storedLines: LineCoordinates[]): void {
    this.ctx.drawImage(this.image, 0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    if (!storedLines.length) {
      return;
    }

    // redraw each stored line
    for (let i = 0; i < storedLines.length; i++) {
      if (!i) {
        this.ctx.beginPath();
        this.ctx.moveTo(storedLines[i].x1, storedLines[i].y1);
      }
      this.ctx.lineTo(storedLines[i].x2, storedLines[i].y2);
      this.ctx.stroke();
    }
  }

  private initiateZoneConfig(): void {
    this.zoneConfiguration = {
      controllerId: '',
      zoneName: '',
      zoneConfigFileUrl: '',
      timestamp: '',
      updatedBy: '',
      xyCoordinates: [],
      workflow: '',
      subType: '',
    };
  }

  private getWorkflowName(workflow: string): string {
    let workflowName = '';
    if (workflow) {
      workflowName = this.workflowData.filter(data => data?.id?.toLowerCase() === workflow)?.[0]?.name;
    }

    return workflowName?.toLowerCase();
  }

  private getWorkflowId(): string {
    let workflowId = '';
    if (this.workflowName) {
      workflowId = this.workflowData.filter(data => data?.name?.toLowerCase() === this.workflowName)?.[0]?.id;
    }

    return workflowId;
  }

  private getZoneConfigHistory(): void {
    this.zoneService
      .zoneConfigHistory(this.controllerId)
      .pipe(
        catchError(() => {
          this.zoneConfigLoader = false;
          this.zoneService.disableRequestImageButton.set(false);

          return of<MediaData>({} as MediaData);
        }),
        tap((data: MediaData) => {
          this.zoneService.disableRequestImageButton.set(false);
          this.setFile(data.media.fileUrl + '/?token=' + this.sessionToken);
          this.showOfflineToaster();
          this.zoneConfigLoader = false;
          this.zoneService.showRequestImageLoader.set(false);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }
}
