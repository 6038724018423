import { Injectable, signal } from '@angular/core';
import { CameraDetails, CameraProfileDetails } from '../models/cameraDetails';
import { BehaviorSubject, EMPTY, Observable, expand, scan } from 'rxjs';
import { EventData, EventDataIteration } from '../models/eventDetails';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { ZoneConfig } from '../models/zoneSetting';
import { toObservable } from '@angular/core/rxjs-interop';
import { EventChart } from '../models/eventChart';
import { Comments, eventComment, FlagEventCount, EventView, EventDataView } from '../models/camera-profile';
import { WorkFlow } from '../models/global-view';

@Injectable({
  providedIn: 'root',
})
export class CameraProfileService {
  public eventData$: Observable<boolean>;
  public eventData = new BehaviorSubject<boolean>(true);
  public isAdmin$: Observable<string>;
  public isAdmin = new BehaviorSubject<string | ''>(sessionStorage.getItem('isAdmin') ?? '');
  public selectedEventTime$: Observable<string | ''>;
  public selectedZoneId$: Observable<ZoneConfig>;
  public sideNavClosed$: Observable<boolean>;
  public sideNavClosed = signal<boolean>(true);
  public cameraWorkflow = signal<string[]>([]);
  public cameraWorkflow$: Observable<string[]>;
  public profileData$: Observable<CameraDetails>;
  public disableZoneBtn = signal<boolean>(true);

  private selectedEventTimeSource = new BehaviorSubject<string | ''>(sessionStorage.getItem('selectedCameraEventDate') ?? '');
  private selectedZoneIdSource = new BehaviorSubject<ZoneConfig>(
    (JSON.parse(sessionStorage.getItem('config') ?? '{}') as ZoneConfig) ?? ({} as ZoneConfig)
  );
  private selectedProfileDataSource = new BehaviorSubject<CameraDetails>({} as CameraDetails);
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
    this.eventData$ = this.eventData.asObservable();
    this.isAdmin$ = this.isAdmin.asObservable();
    this.selectedEventTime$ = this.selectedEventTimeSource.asObservable();
    this.selectedZoneId$ = this.selectedZoneIdSource.asObservable();
    this.sideNavClosed$ = toObservable(this.sideNavClosed);
    this.cameraWorkflow$ = toObservable(this.cameraWorkflow);
    this.profileData$ = this.selectedProfileDataSource.asObservable();
    const zoneData = sessionStorage.getItem('config');
    if (zoneData) {
      this.setZoneId(JSON.parse(zoneData) as ZoneConfig);
    }
  }

  public getCameraDetails(
    gatewayId: string,
    controllerId: string,
    reviewCategory: string,
    startTime: string,
    endTime: string,
    showAllEvents: string
  ): Observable<CameraProfileDetails> {
    return this.http.get<CameraProfileDetails>(
      /* eslint-disable max-len */
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.CAMERAPROFILE}get-camera-summary?gatewayId=${gatewayId}
&controllerId=${controllerId}&startDate=${startTime}&endDate=${endTime}&reviewCategory=${reviewCategory}&showAllEvents=${showAllEvents}`
    );
  }

  public getEventTrendDetails(
    gatewayId: string,
    controllerId: string,
    startTime: string,
    endTime: string,
    countryName: string,
    reviewCategory: string,
    timeZone: string,
    showAllEvents: string
  ): Observable<EventChart> {
    return this.http.get<EventChart>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-event-trend?gatewayId=${gatewayId}
&startDate=${startTime}&endDate=${endTime}&reviewCategory=${reviewCategory}
&countryName=${countryName}&controllerId=${controllerId}&timeZone=${timeZone}&showAllEvents=${showAllEvents}`
    );
  }

  public getViewCounts(eventId: number): Observable<EventView> {
    return this.http.get<EventView>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-event-views/${eventId}`);
  }

  public createViewCounts(eventId: number, userName: string, userEmail: string, acsUserId: number): Observable<EventView> {
    const param = {
      eventId,
      userName,
      userEmail,
      acsUserId,
    };

    return this.http.post<EventView>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}add-event-views`, param);
  }

  public getCategoryData(eventId: number): Observable<FlagEventCount> {
    return this.http.get<FlagEventCount>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-flags/${eventId}`);
  }

  public setFlagData(eventId: number, userName: string, userEmail: string, flag: string): Observable<EventDataView> {
    const param = {
      eventId,
      userName,
      userEmail,
      flag,
    };

    return this.http.post<EventDataView>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}add-flag`, param);
  }

  public getCameraEventData(
    gatewayId: string,
    controllerId: string,
    startDate: string,
    endDate: string,
    pageNo: number,
    pageSize: number,
    reviewCategory: string,
    eventType: string[],
    showAllEvents: string
  ): Observable<EventData> {
    const param = {
      gatewayId,
      controllerId,
      startDate,
      endDate,
      reviewCategory,
      eventType,
      showAllEvents,
      pageNo,
      pageSize,
    };

    return this.http.post<EventData>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.CAMERAPROFILE}get-camera-alert-list`, param);
  }

  public downloadMediaAsZip(gatewayId: string, controllerId: string, eventIds: number[]): Observable<any> {
    const param = {
      gatewayId,
      controllerId,
      eventIds,
    };

    return this.http.post(`${this.baseUrl}${ApiConstants.FILEAPIBASEURL}download-alert-media-data`, param, {
      responseType: 'arraybuffer',
    });
  }

  public getEventData(value: boolean): void {
    this.eventData.next(value);
  }

  public setAdmin(admin: string): void {
    this.isAdmin.next(admin);
  }

  public setEventTime(EventTime: string | ''): void {
    this.selectedEventTimeSource.next(EventTime);
  }

  public setZoneId(zoneConfig: ZoneConfig): void {
    this.selectedZoneIdSource.next(zoneConfig);
  }

  public setProfileData(profileData: CameraDetails): void {
    this.selectedProfileDataSource.next(profileData);
  }

  public getAllEventData(
    gatewayId: string,
    controllerId: string,
    startTime: string,
    endTime: string,
    pageNumber: number,
    recordsPerPage: number,
    reviewCategory: string,
    event: string[],
    showAllEvent: string
  ): Observable<EventDataIteration> {
    let totalPages = 0;

    return this.getCameraEventData(
      gatewayId,
      controllerId,
      startTime,
      endTime,
      pageNumber,
      recordsPerPage,
      reviewCategory,
      event,
      showAllEvent
    ).pipe(
      expand((response: EventData) => {
        const dataResponse: EventData = JSON.parse(JSON.stringify(response));
        if (dataResponse.totalRecords && totalPages !== pageNumber) {
          return this.getCameraEventData(
            gatewayId,
            controllerId,
            startTime,
            endTime,
            ++pageNumber,
            recordsPerPage,
            reviewCategory,
            event,
            showAllEvent
          );
        } else {
          pageNumber = 1;
          this.getCameraEventData(
            gatewayId,
            controllerId,
            startTime,
            endTime,
            pageNumber,
            recordsPerPage,
            reviewCategory,
            event,
            showAllEvent
          );

          return EMPTY;
        }
      }),
      scan((accumulatedData: EventDataIteration, currentData: EventData, index: number) => {
        if (Object.keys(accumulatedData).length === 0) {
          totalPages = Math.round(currentData.totalRecords / recordsPerPage);
          totalPages = currentData.totalRecords % recordsPerPage ? ++totalPages : totalPages;
          accumulatedData = {
            ...currentData,
            index,
            totalPages,
          };
        } else {
          accumulatedData.data = [...currentData.data];
          accumulatedData.index = index;
        }

        return accumulatedData;
      }, {} as EventDataIteration)
    );
  }

  public getEventComments(eventId: number): Observable<Comments> {
    return this.http.get<Comments>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-comments/${eventId}`);
  }

  public saveEventComments(comment: eventComment): Observable<Comments> {
    return this.http.post<Comments>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}add-comments`, comment);
  }

  public resetAnalyticsSelection(): void {
    sessionStorage.removeItem('analyticsSelection');
    sessionStorage.removeItem('dateRange');
    sessionStorage.removeItem('customStartDate');
    sessionStorage.removeItem('customEndDate');
  }

  public getWorkFlowByControllerId(controllerId: string): Observable<WorkFlow[]> {
    return this.http.get<WorkFlow[]>(
      /* eslint-disable max-len */
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}workflow-by-equipment/${controllerId}`
    );
  }
}
